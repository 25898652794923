<template>
  <div
    :style="primaryVersion ? '--bg-color:var(--background-primary)' : '--bg-color:var(--background-secondary)'"
    :class="['ui-input', this.class, { 'error-input': error, 'hide-label': hideLabel }]"
  >
    <select
      :disabled="disabled"
      :value="value"
      @input.stop="
        onInput($event);
        noSetValue ? ($event.target.value = undefined) : null;
      "
    >
      <slot></slot>
    </select>
    <div
      class="placeholder"
      :class="{ active: Boolean(value) }"
      @click="clickSelect()"
    >
      <span>{{ placeholder }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UISelectInput",
    watch: {
      modelValue() {
        if (this.value != this.modelValue) this.value = this.modelValue;
      },
    },
    props: {
      disabled: { type: Boolean, required: false, default: false },
      modelValue: { type: [String, Number], required: false },
      class: { type: String, required: false, default: "" },
      placeholder: { type: String, required: false, default: "" },
      error: { type: Boolean, default: false },
      hideLabel: { type: Boolean, default: false },
      noSetValue: { type: Boolean, default: false },
      primaryVersion: { type: Boolean, default: false },
    },
    data: () => ({
      value: "",
      saveValue: "",
    }),
    methods: {
      onInput(event) {
        if (!this.noSetValue) this.value = event.target.value;
        this.$emit("input", event);
        this.$emit("update:modelValue", this.value);
      },
      clickSelect() {
        this.$el.querySelector("select").click();
      },
    },
    mounted() {
      this.value = this.saveValue = this.modelValue;
    },
  };
</script>

<style lang="scss" scoped>
  .ui-input {
    --bg-label: var(--background-primary);
    --radius: 5px;
    --padding: 10px 12px;
    --color: var(--text-secondary);
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 48px;
    &.error-input {
      select {
        border: 1px solid var(--red);
      }

      div {
        color: var(--red) !important;
      }
    }

    &.hide-label {
      div {
        &.active {
          display: none !important;
        }
      }
    }

    select {
      cursor: pointer;
      padding: var(--padding);
      width: 100%;
      height: 100%;
      color: var(--text-primary);
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      //border-radius: var(--radius);
      border: 1px solid #dddce8;
      background-color: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 8px;
    }
    &::after {
      content: ".";
      font-size: 0px;
      position: absolute;
      pointer-events: none;
      background-repeat: no-repeat;
      right: 8px;
      top: 30%;
      height: 20px;
      width: 28px;
      //background-color: var(--bg-color);
      background-position: center center;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBjZDRDYAgDEQPGUBnYBYGcgNxAhfiQ/+cglm8S+CHVMIlTdPrvaSpizHuANac84kJ1TyWOicaxwSkzMXafCnlDSE4wezg/AygxDp5XfIyGb5HcA/J8235B1uQ5IyTtGxhWJAJdjAsaCjB7fWWPtufPZa0gxjnAAAAAElFTkSuQmCC");
    }
    .placeholder {
      pointer-events: none;
      padding: 0 4px;
      position: absolute;
      top: 50%;
      left: 12px;
      color: #6f6f6f;
      white-space: nowrap;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transition: 0.2s;
      user-select: none;
      overflow: hidden;
      width: 90%;

      &::before {
        content: "";
        width: 105%;
        height: 1px;
        position: absolute;
        top: 6px;
        left: -2.5%;
        z-index: 1;
      }

      span {
        position: relative;
        z-index: 2;
      }

      &.active {
        top: -6px;
        color: var(--color);
        font-size: 12px;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        width: max-content;

        &::before {
          background: var(--bg-label);
        }
      }
    }
  }
</style>
