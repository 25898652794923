import $api from "@/http";

class UserService {
    createFormData = (data) => {
        let formData = new FormData();
        for (let key in data) {
            if (Array.isArray(data[key])) for (let i in data[key]) formData.append(key, data[key][i]);
            else if (key !== "callback") formData.append(key, data[key] ? data[key] : "");
        }
        return formData;
    };

    // ? User
    async getUser() {
        return await $api.get("/users/get-account/");
    }

    async updateUser(userData) {
        return await $api.patch("/users/participant/update_profile/", userData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async closeProfileTemporary(profileId) {
        console.log('профайл айді в UserService', profileId)
        return await $api.post("users/profile-suspend/", {
            profile_id: profileId,
            is_permanent: false
        });
    }

    async deleteProfilePermanent(profileId, reason) {
        return await $api.delete("users/profile-suspend/", {
                data: {
                    profile_id: profileId,
                    is_permanent: true
                }
            }
        );
    }

    async deleteAccountPermanent(reason) {
        return await $api.post("https://api.proconnect.construction/api/v1/users/close-account/", {
            reason,
            is_permanent: true
        });
    }

    async confirmResignation(participant_id, reason_id, is_permanent, token) {
        return await $api.post(`/users/confirm-resignation/${participant_id}/${reason_id}/${is_permanent}/${token}/`);
    }

    // ? Auth
    async facebookAuth(authResponse) {
        return await $api.post("/users/social-auth/facebook_auth/", authResponse);
    }

    async googleAuth(authResponse) {
        return await $api.post("/users/social-auth/google_auth_serverside/", authResponse);
    }

    // ? List Roles
    async getAllCountries() {
        return await $api.get("/users/countries/");
    }

    async getAllRoles() {
        return await $api.get("/users/roles/");
    }

    async getAllBuildingTypes() {
        return await $api.get("/users/buildingtypes/");
    }

    async getAllWorkTypes() {
        return await $api.get("/users/worktypes/");
    }

    // ? Search
    async searchParticipants(query, searchBy = "name__contains") {
        const url = `/users/search-accounts?${searchBy}=${query}`;
        console.log('Запит:', url);
        return await $api.get(url);
    }



    async searchUsers(query, searchBy = "name__contains") {
        return await $api.get(`/users/search-accounts/?${searchBy}=${query}`);
    }




    async searchProjects(query, searchBy = "title__contains") {
        return await $api.get(`/projects/search-projects/?${searchBy}=${query}`);
    }

    // ? Licences
    async addLicence(licenceData) {
        return await $api.post("/users/profile-licences/", licenceData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async deleteLicence(licenceId) {
        return await $api.delete(`/users/profile-licences/${licenceId}/`);
    }

    async editLicence(licenceData) {
        return await $api.patch(`/users/profile-licences/${licenceData.get("id")}/`, licenceData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    // ? Permissions
    async addPermission(permissionData) {
        return await $api.post("/users/profile-permissions/", permissionData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async deletePermission(permissionId) {
        return await $api.delete(`/users/profile-permissions/${permissionId}/`);
    }

    async editPermission(permissionData) {
        return await $api.patch(`/users/profile-permissions/${permissionData.get("id")}/`, permissionData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    // ? Certificate
    async addCertificate(certificateData) {
        return await $api.post("/users/profile-certificates/", certificateData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async deleteCertificate(certificateId) {
        return await $api.delete(`/users/profile-certificates/${certificateId}/`);
    }

    async editCertificate(certificateData) {
        return await $api.patch(`/users/profile-certificates/${certificateData.get("id")}/`, certificateData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    // ? Awards
    async addAward(awardData) {
        return await $api.post("/users/participant-awards/", awardData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async deleteAward(awardId) {
        return await $api.delete(`/users/participant-awards/${awardId}/`);
    }

    async editAward(awardData) {
        return await $api.patch(`/users/participant-awards/${awardData?.id}/`, awardData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    // ? TeamMembers
    async getTeamMembers(userId) {
        return await $api.get(`/users/teammember-list/?participant=${userId}`);
    }

    async addTeamMember(memberData) {
        return await $api.post("/users/team-member/", memberData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async editTeamMember(memberData) {
        return await $api.patch(`/users/team-member/${memberData.get("id")}/`, memberData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async deleteTeamMember(memberId) {
        return await $api.delete(`/users/team-member/${memberId}/`);
    }

    // ? Partners
    async getPartners(userId) {
        return await $api.get(`/users/participant-partnership/?participant=${userId}&status=ACTIVE`);
    }

    async getAllRequestPartners(userId) {
        return await $api.get(`/users/participant-partnership/?participant=${userId}`);
    }

    async addPartner(partnerData) {
        return await $api.post("/users/participant-partnership/", partnerData);
    }

    async editPartner(partnerData) {
        return await $api.patch(`/users/participant-partnership/${partnerData?.id}/`, partnerData);
    }

    async deletePartner(partnerId) {
        return await $api.delete(`/users/participant-partnership/${partnerId}/`);
    }

    // ? Education
    async addEducation(educationData) {
        return await $api.post("/users/participant-education/", educationData);
    }

    async editEducation(educationData) {
        return await $api.patch(`/users/participant-education/${educationData.id}/`, educationData);
    }

    async deleteEducation(educationId) {
        return await $api.delete(`/users/participant-education/${educationId}/`);
    }

    // ? Talk
    async addTalk(talkingData) {
        return await $api.post("/users/participant-talkingaboutme/", talkingData);
    }

    async editTalk(talkingData) {
        return await $api.patch(`/users/participant-talkingaboutme/${talkingData.id}/`, talkingData);
    }

    async deleteTalk(talkingId) {
        return await $api.delete(`/users/participant-talkingaboutme/${talkingId}/`);
    }

    // ? Info
    async addInfo(infoData) {
        return await $api.post("/users/participant-extrainfo/", infoData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async editInfo(id, infoData) {
        return await $api.patch(`/users/participant-extrainfo/${id}/`, infoData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    }

    async deleteInfo(infoId) {
        return await $api.delete(`/users/participant-extrainfo/${infoId}/`);
    }

    // ? Notifications
    async userNotifications(userId, {page = 1} = {}) {
        return await $api.get(`/notifications/?participant=${userId}&page=${page}`);
    }

    async readNotifications(listNotifications = []) {
        return await $api.post(`/notifications/read/`, {viewed: listNotifications});
    }

    async readAllNotifications(listVieweds) {
        if (listVieweds?.length == 0) return;
        return await $api.patch(`/notifications/read/`, {
            viewed: listVieweds,
        });
    }

    async deleteNotification(id) {
        return await $api.delete(`/notifications/${id}/`);
    }

    // ? Connection
    async getConnectionList(userId, {page = 1} = {}) {
        return await $api.get(`/users/connection?page=${page}`);
    }

    async createConnection(subscribed_to) {
        return await $api.post(`/users/connection/`, {subscribed_to});
    }

    async deleteConnection(id) {
        return await $api.delete(`/users/connection/${id}/`);
    }

    // ? Review-request
    async getReviewRequestList(userId) {
        return await $api.get(`/projects/review-request?initializer=${userId}`);
    }

    async createReviewRequest(data) {
        return await $api.post(`/projects/review-request/`, data);
    }

    async acceptReviewRequest(data) {
        return await $api.post(`/projects/review-request-accept/`, data);
    }

    /**
     * @param { "accept_partner" | "decline_partner" } actionName
     */
    async completeNotifications(notificationId, actionName, actionId, inputValue = "This is my friend") {
        return await $api.post(`/notifications/complete_action/`, {
            notification_id: notificationId,
            action: actionName,
            action_data: {
                id: actionId,
                input_value: inputValue,
            },
        });
    }

    // ? Invitation
    inviteFriend(params) {
        return $api.post(`/users/invite-friend/`, null, {params});
    }
}

export default new UserService();
