import $api from "@/http";

class AuthService {
  async login(email, password) {
    return await $api.post("/users/login/", { email, password });
  }

  async signUp(email, full_name, password) {
    return await $api.post("/users/signup/", { email, full_name, password });
  }

  async refreshToken(token) {
    return await $api.post("/users/refresh-login/", { refresh: token });
  }
  async passwordReset(email) {
    return await $api.post("/password-reset/", { email: email });
  }
  async activate(id, uid) {
    return await $api.get(`/activate/${id}/${uid}/`);
  }
}

export default new AuthService();
