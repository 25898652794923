<template>
  <div class="settings-block delete-profile">
    <div class="delete-profile-container">
      <div class="delete-profile-back">
        <button class="back-button" data-component="MainSettings" @click="handleClick">
          <img src="@/assets/img/arrow-left.svg" alt="стрілка назад">
          <span>Назад</span>
        </button>
      </div>
      <div class="delete-profile-title">Видалення профілю</div>
      <div class="delete-profile-content content">
        <p class="content-text">Ми дуже шкодуємо, ________________, що ви вирішили видалити свій профіль на платформі PRO Connect.
          Будь ласка, повідомте нам причину цього рішення:</p>
        <form action="">
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">У мене є дублікат профілю</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Мене турбує безпека мої даних;</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Я не отримую цінності від користування;</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Неправдиві відгуки шкодять моїй репутації;</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Інше</label>
          </div>

          <PlaceholderTextInput
              typeTag="textarea"
              placeholder="Опис*"
          />
        </form>
        <p class="content-subtext">Ми дуже цінуємо вашу думку і обов’язково приймемо її до уваги, щоб зробити PRO Connect максимально корисним та цікавим для всіх учасників.
          Звертаємо вашу увагу, що після видалення профілю картки всіх ваших проєктів, зв'язки та відгуки цього профілю будуть видалені, і інші користувачі PRO Connect більше не зможуть їх побачити.
          Після видалення профілю, відновити інформацію буде неможливо, і ви одразу втратите доступ до свого профілю.</p>
      </div>
      <div class="delete-profile-buttons">
        <ButtonWhite @click="deleteProfilePermanent"> Видалити</ButtonWhite>
        <Button> Скасувати</Button>
      </div>

    </div>

  </div>

</template>

<script>
    import Button from "@/components/UI/Button.vue";
    import UserService from "@/services/UserService";
    import ButtonWhite from "@/components/UI/ButtonWhite.vue";
    import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";

    export default {
        data() {
            return {
            };
        },
        props: {
            loadUser: {
                type: Object,
                required: true,
            },
        },
        methods: {
          async deleteProfilePermanent() {
            const profileId = this.activeProfile.id;
            if (profileId) {
              const response = await UserService.deleteProfilePermanent(profileId);
              if (response.status === 200) {
                alert("Повідомлення про видалення профілю відправлено на емейл");
              } else {
                alert(response.data);
              }
            }
          },
          handleClick($event) {

            this.$emit("changeComponent", $event);
            console.log('назад')
          },
        },
        components: {PlaceholderTextInput, ButtonWhite, Button },
    };
</script>


<style lang="scss" scoped>
.delete-profile {
  .delete-profile-container {
    border-right: 10px;
    background-color: #fff;
    padding: 20px;
    max-width: 728px;
    .delete-profile-back {
      padding: 10px 10px 15px 0;
      .back-button {
        display: flex;
        align-items: center;
        gap: 10px;
        border: none;
        background: none;
        color: #6F6F6F;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .delete-profile-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .content {
      .content-text {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 30px;
      }
      form {
        margin-bottom: 10px;
        div {
          margin-bottom: 12px;
          input {
            margin: 0.4rem;
          }
          label {
            color: #6F6F6F;
            font-size: 18px;
          }
        }
      }
      .content-subtext {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }
    .delete-profile-buttons {
      display: flex;
      gap: 14px;
    }
  }
}
</style>
