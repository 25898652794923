import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";

let createFormData = (data) => {
  let formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) for (let i in data[key]) formData.append(key, data[key][i]);
    else if (key !== "callback") formData.append(key, data[key] ? data[key] : "");
  }
  return formData;
};

export default {
  namespaced: true,
  state: () => ({
    user: {
      authorized: false,
    },
  }),
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    updateUser(state, payload) {
      state.user = { ...state.user, ...payload };
    },
  },
  actions: {
    async loginAttempt(context, userData) {
      try {
        let { data } = await AuthService.login(userData.email, userData.password);
        localStorage.setItem("refresh_token", data.refresh);
        localStorage.setItem("access_token", data.access);
        context.commit("updateUser", { authorized: true });
        await context.dispatch("getUser");
      } catch (e) {
        console.log(e);
      }
    },
    async refreshToken(context, token) {
      try {
        let { data } = await AuthService.refreshToken(token);
        localStorage.setItem("access_token", data.access);
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    async getUser(context) {
      try {
        let { data, status } = await UserService.getUser();
        if (status === 202 || status === 200 || status === 201) {
          context.commit("updateUser", data);
        }
        await context.dispatch("getTeamMembers");
        await context.dispatch("getPartners");
        await context.dispatch("getConnectionList");
      } catch (e) {
        console.log(e);
      }
    },
    leaveFromAccount(context) {
      context.commit("setUser", { authorized: false });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      // mixin.methods.router('/login');
    },
    async updateUser(context, userData) {
      try {
        let formData = createFormData(userData);
        let { status, data: responseData } = await UserService.updateUser(formData);
        if (status === 202) {
          context.commit("updateUser", responseData);
          userData?.callback({ status, data: responseData });
        }
      } catch (e) {
        console.log(e);
      }
    },
    setUser(context, data) {
      context.commit("updateUser", data);
    },
    async facebookAuth(context, response) {
      try {
        await UserService.facebookAuth(response);
      } catch (e) {
        console.log(e);
      }
    },
    async googleAuth(context, response) {
      try {
        await UserService.googleAuth(response);
      } catch (e) {
        console.log(e);
      }
    },
    async addLicence(context, licenceData) {
      try {
        let formData = createFormData(licenceData);
        let { status, data: responseData } = await UserService.addLicence(formData);
        if (status === 201) {
          context.commit("updateUser", { licence_set: [...context.state.user.licence_set, responseData] });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteLicence(context, licenceId) {
      try {
        let { status } = await UserService.deleteLicence(licenceId);
        if (status === 204) {
          context.commit("updateUser", {
            licence_set: context.state.user.licence_set.filter((licence) => licence.id !== licenceId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editLicence(context, licenceData) {
      let oldLicenceSet = context.state.user.licence_set;
      try {
        let formData = createFormData(licenceData);
        let { status, data: responseData } = await UserService.editLicence(formData);
        if (status === 200) {
          context.state.user.licence_set[
            context.state.user.licence_set.findIndex((obj) => obj.id === responseData.id)
          ] = responseData;
          // context.commit('updateUser', { licence_set: [...context.state.user.licence_set, responseData] });
        } else {
          context.commit("updateUser", { licence_set: oldLicenceSet });
        }
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { licence_set: oldLicenceSet });
      }
    },
    async addPermission(context, permissionData) {
      try {
        let formData = createFormData(permissionData);
        let { status, data: responseData } = await UserService.addPermission(formData);
        if (status === 201) {
          context.commit("updateUser", {
            permission_set: [...context.state.user.permission_set, responseData],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deletePermission(context, permissionId) {
      try {
        let { status } = await UserService.deletePermission(permissionId);
        if (status === 204) {
          context.commit("updateUser", {
            permission_set: context.state.user.permission_set.filter((permission) => permission.id !== permissionId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editPermission(context, permissionData) {
      let oldPermissionSet = context.state.user.permission_set;
      try {
        let formData = createFormData(permissionData);
        let { status, data: responseData } = await UserService.editPermission(formData);
        if (status === 200)
          context.state.user.permission_set[
            context.state.user.permission_set.findIndex((obj) => obj.id === responseData.id)
          ] = responseData;
        else context.commit("updateUser", { permission_set: oldPermissionSet });
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { permission_set: oldPermissionSet });
      }
    },
    async addCertificate(context, certificateData) {
      try {
        let formData = createFormData(certificateData);
        let { status, data: responseData } = await UserService.addCertificate(formData);
        if (status === 201) {
          context.commit("updateUser", {
            certificate_set: [...context.state.user.certificate_set, responseData],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCertificate(context, certificateId) {
      try {
        let { status } = await UserService.deleteCertificate(certificateId);
        if (status === 204) {
          context.commit("updateUser", {
            certificate_set: context.state.user.certificate_set.filter((cert) => cert.id !== certificateId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editCertificate(context, certificateData) {
      let oldCertificateSet = context.state.user.certificate_set;
      try {
        let formData = createFormData(certificateData);
        let { status, data: responseData } = await UserService.editCertificate(formData);
        if (status === 200)
          context.state.user.certificate_set[
            context.state.user.certificate_set.findIndex((obj) => obj.id === responseData.id)
          ] = responseData;
        else context.commit("updateUser", { certificate_set: oldCertificateSet });
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { certificate_set: oldCertificateSet });
      }
    },
    async addAward(context, awardData) {
      try {
        let formData = createFormData(awardData);
        let { status, data: responseData } = await UserService.addAward(formData);
        if (status === 201) {
          context.commit("updateUser", { award_set: [...context.state.user.award_set, responseData] });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteAward(context, awardId) {
      try {
        let { status } = await UserService.deleteAward(awardId);
        if (status === 204) {
          context.commit("updateUser", {
            award_set: context.state.user.award_set.filter((award) => award.id !== awardId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editAward(context, awardData) {
      let oldAwardSet = context.state.user.award_set;
      try {
        let formData = createFormData(awardData);
        let { status, data: responseData } = await UserService.editAward(formData);
        if (status === 200)
          context.state.user.award_set[context.state.user.award_set.findIndex((obj) => obj.id === responseData.id)] =
            responseData;
        else context.commit("updateUser", { award_set: oldAwardSet });
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { award_set: oldAwardSet });
      }
    },
    async getPartners(context) {
      try {
        let { data: partners } = await UserService.getPartners(context.state.user.id),
          data = [];

        if (partners.count > 0) {
          data = partners.results || [];
        }
        context.commit("updateUser", { partners_set: data });
      } catch (e) {
        console.log(e);
      }
    },
    async addPartner(context, memberData) {
      try {
        let formData = createFormData(memberData);
        let { status, data: responseData } = await UserService.addTeamMember(formData);
        let { data: allRoles } = await UserService.getAllRoles();
        let member = {
          ...responseData,
          searchVariants: { member: [], role: [] },
          member_role_input: allRoles.find((role) => role.id === responseData.member_role).title,
        };
        if (status === 201) {
          context.commit("updateUser", { teammember_set: [...context.state.user.teammember_set, member] });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editPartner(context, memberData) {
      let oldTeamMemberSet = context.state.user.teammember_set;
      try {
        let formData = createFormData(memberData);
        let { status, data: responseData } = await UserService.editTeamMember(formData);
        if (status === 200)
          context.state.user.teammember_set[
            context.state.user.teammember_set.findIndex((obj) => obj.id === responseData.id)
          ] = responseData;
        else context.commit("updateUser", { teammember_set: oldTeamMemberSet });
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { teammember_set: oldTeamMemberSet });
      }
    },
    async deletePartner(context, memberId) {
      try {
        let { status } = await UserService.deleteTeamMember(memberId);
        if (status === 204) {
          context.commit("updateUser", {
            teammember_set: context.state.user.teammember_set.filter((member) => member.id !== memberId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getTeamMembers(context) {
      try {
        let { data: teammembers } = await UserService.getTeamMembers(context.state.user.id),
          data = [];

        if (teammembers.count > 0) {
          data = teammembers.results || [];
        }
        context.commit("updateUser", { teammember_set: data });
      } catch (e) {
        console.log(e);
      }
    },
    async getConnectionList(context) {
      try {
        let { status, data } = await UserService.getConnectionList();
        if (status === 200) {
          const connectionList = {};
          data.results.forEach((row) => {
            connectionList[row.subscribed_to] = { id: row.id };
          });
          context.commit("updateUser", { connectionList });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addTeamMember(context, memberData) {
      try {
        let formData = createFormData(memberData);
        let { status, data: responseData } = await UserService.addTeamMember(formData);
        let { data: allRoles } = await UserService.getAllRoles();
        let member = {
          ...responseData,
          searchVariants: { member: [], role: [] },
          member_role_input: allRoles.find((role) => role.id === responseData.member_role).title,
        };
        if (status === 201) {
          context.commit("updateUser", { teammember_set: [...context.state.user.teammember_set, member] });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editTeamMember(context, memberData) {
      let oldTeamMemberSet = context.state.user.teammember_set;
      try {
        let formData = createFormData(memberData);
        let { status, data: responseData } = await UserService.editTeamMember(formData);
        if (status === 200)
          context.state.user.teammember_set[
            context.state.user.teammember_set.findIndex((obj) => obj.id === responseData.id)
          ] = responseData;
        else context.commit("updateUser", { teammember_set: oldTeamMemberSet });
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { teammember_set: oldTeamMemberSet });
      }
    },
    async deleteTeamMember(context, memberId) {
      try {
        let { status } = await UserService.deleteTeamMember(memberId);
        if (status === 204) {
          context.commit("updateUser", {
            teammember_set: context.state.user.teammember_set.filter((member) => member.id !== memberId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addNewEducation(context, educationData) {
      try {
        let { status, data: responseData } = await UserService.addEducation(educationData);
        if (status === 201) {
          context.commit("updateUser", {
            education_set: [...context.state.user.education_set, responseData],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editEducation(context, educationData) {
      let oldEducationSet = context.state.user.education_set;
      try {
        let { status, data: responseData } = await UserService.editEducation(educationData);
        if (status === 200)
          context.state.user.education_set[
            context.state.user.education_set.findIndex((obj) => obj.id === responseData.id)
          ] = responseData;
        else context.commit("updateUser", { education_set: oldEducationSet });
      } catch (e) {
        console.log(e);
        context.commit("updateUser", { education_set: oldEducationSet });
      }
    },
    async deleteEducation(context, educationId) {
      try {
        let { status } = await UserService.deleteEducation(educationId);
        if (status === 204) {
          context.commit("updateUser", {
            education_set: context.state.user.education_set.filter((education) => education.id !== educationId),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
};
