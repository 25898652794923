export default class BaseModel {
  #old = null;
  constructor(data) {
    if (new.target === BaseModel) {
      throw new Error("Cannot instantiate abstract class BaseModel directly");
    }
    this.id = data.id ?? "";
    this.title = data.title ?? "";
    this.#old = data.title;
  }

  get oldProperty() {
    return this.#old;
  }
  get err() {
    return this.hasError;
  }
  set err(data) {
    this.hasError = data;
  }
  // Абстрактний метод, який повинен бути перевизначений в дочірніх класах
  async save() {
    throw new Error("Method 'save()' must be implemented.");
  }
  async delete() {
    throw new Error("Method 'delete()' must be implemented.");
  }

  get isValid() {
    throw new Error("Method 'isValid()' must be implemented.");
  }
  get hasChanges() {
    throw new Error("Method 'hasChanges()' must be implemented.");
  }
  static async load(id) {
    throw new Error("Method 'load()' must be implemented.");
  }
}
