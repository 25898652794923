<template>
  <div class="settings-block delete-account">
    <div class="delete-account-container">
      <div class="delete-account-back">
        <a @click="this.router(`/settings`)" href="">
          <img src="@/assets/img/arrow-left.svg" alt="">
          <span>Назад</span>
        </a>
      </div>
      <div class="delete-account-title">Видалення облікового запису</div>
      <div class="delete-account-content content">
        <p class="content-text">Ми дуже шкодуємо, ______________, що ви вирішили видалити свій обліковий запис на платформі PRO Connect.
          Будь ласка, повідомте нам причину цього рішення:</p>
        <form action="">
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">У мене є дублікат облікового запису</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Мене турбує безпека мої даних;</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Я не отримую цінності від користування;</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Неправдиві відгуки шкодять моїй репутації;</label>
          </div>
          <div>
            <input type="checkbox" id="" name=""/>
            <label for="">Інше</label>
          </div>

          <PlaceholderTextInput
              typeTag="textarea"
              placeholder="Опис*"
          />
        </form>
        <p class="content-subtext">Ми дуже цінуємо вашу думку і обов’язково приймемо її до уваги, щоб зробити PRO Connect максимально корисним та цікавим для всіх учасників.
          Звертаємо вашу увагу, що після видалення облікового запису ваш профіль, де ви зазначені як "Власник", а також картки всіх ваших проєктів і відгуки будуть видалені. Інші користувачі PRO Connect більше не зможуть їх побачити.
          Після видалення облікового запису, відновити інформацію буде неможливо, і ви одразу втратите доступ до свого облікового запису.</p>
      </div>
      <div class="close-account-buttons">
        <ButtonWhite @click="deleteAccountPermanent()"> Видалити</ButtonWhite>
        <Button> Скасувати</Button>
      </div>

    </div>

  </div>

</template>

<script>
    import Button from "@/components/UI/Button.vue";
    import UserService from "@/services/UserService";
    import ButtonWhite from "@/components/UI/ButtonWhite.vue";
    import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";

    export default {
        data() {
            return {};
        },
        props: {
            loadUser: {
                type: Object,
                required: true,
            },
        },
        methods: {
            async deleteAccountPermanent() {
                const reason = prompt("Вкажіть причину");
                if (reason) {
                    const response = await UserService.deleteAccountPermanent(reason);
                    if (response.status == 200) {
                        // this.openedModalSuccess = true;
                        alert("Повідомлення про видалення облікового запису відправлено на емейл");
                    } else {
                        alert(response.data);
                    }
                }
            },
        },
        components: {PlaceholderTextInput, ButtonWhite, Button },
    };
</script>

<style lang="scss" scoped>
.delete-account {
  .delete-account-container {
    border-right: 10px;
    background-color: #fff;
    padding: 20px;
    max-width: 728px;
    .delete-account-back{
      padding: 10px 10px 15px 0 ;
      a{
        text-decoration: none;
        color: #6F6F6F;
        display: flex;
        gap:10px;
      }
    }
    .delete-account-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .content {
      .content-text {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 30px;
      }
      form{
        margin-bottom:10px;
        div{
          margin-bottom: 12px;
          input{
            margin:0.4rem;
          }
          label{
            color:#6F6F6F;
            font-size:18px;

          }
        }

      }
      .content-subtext{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }
    .delete-account-buttons{
      display: flex;
      gap:14px;
    }
  }

}
</style>
