<template>
  <div class="settings-block close-profile">
    <div class="close-profile-container">
      <div class="close-profile-back">
        <button class="back-button" data-component="MainSettings" @click="handleClick">
          <img src="@/assets/img/arrow-left.svg" alt="стрілка назад">
          <span>Назад</span>
        </button>
      </div>
      <div class="close-profile-title">Тимчасова деактивація профілю</div>
      <div class="close-profile-content content">
        <p class="content-text">Ми дуже шкодуємо, _____________, що ви вирішили тимчасово деактивувати свій профіль на
          платформі PRO Connect.
          Будь ласка, повідомте нам причину цього рішення:</p>
        <form @submit.prevent="closeProfileTemporary">
          <div>
            <input type="checkbox" id="reason1" v-model="reasons" value="Тимчасово призупиняю свою підприємницьку діяльність"/>
            <label for="">Тимчасово призупиняю свою підприємницьку діяльність</label>
          </div>
          <div>
            <input type="checkbox" id="reason2" v-model="reasons" value="Хочу зробити перерву в користуванні"/>
            <label for="">Хочу зробити перерву в користуванні;</label>
          </div>
          <div>
            <input type="checkbox" id="reason3" v-model="reasons" value="Мене турбує безпека мої даних"/>
            <label for="">Мене турбує безпека мої даних;</label>
          </div>
          <div>
            <input type="checkbox" id="reason4" v-model="reasons" value="Я не отримую цінності від користування"/>
            <label for="">Я не отримую цінності від користування;</label>
          </div>
          <div>
            <input type="checkbox" d="reason5" v-model="reasons" value="Інше"/>
            <label for="">Інше</label>
          </div>

          <PlaceholderTextInput
              typeTag="textarea"
              placeholder="Опис*"
          />
        </form>
        <p class="content-subtext">Ми цінуємо Вашу думку!
          Дякуємо, що Ви допомогаєте зробити PRO Connect кращим для всіх учасників. Будь ласка, зверніть увагу, що для
          відновлення доступу до вашого профілю, потрібно буде перейти в меню "Профілі" та натиснути кнопку "Відновити"
          біля відповідного профілю. Ваш профіль буде відновлений протягом 48 годин.</p>
      </div>
      <div class="close-profile-buttons">
        <ButtonWhite @click="closeProfileTemporary"> Тимчасова деактивувати</ButtonWhite>
        <Button :data-component="'MainSettings'" @click="handleClick"> Скасувати</Button>
      </div>

    </div>

  </div>
</template>

<script>
// import ButtonDanger from "@/components/UI/ButtonDanger.vue";
import UserService from "@/services/UserService";
import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";
import ButtonWhite from "@/components/UI/ButtonWhite.vue";
import Button from "@/components/UI/Button.vue";

export default {
  data() {
    return {
      reasons: [],
      customReason: ''
    };
  },
  props: {
    loadUser: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async closeProfileTemporary() {
      const profileId = this.activeProfile.id;
      console.log('профайл айді в CloseProfile',profileId)
      if (profileId) {
          const response = await UserService.closeProfileTemporary(profileId);
          if (response.status === 200) {
            alert("Повідомлення з тимчасовою деактивацією профілю відправлено на емейл");
          } else {
            alert(response.data);
          }
      }
    },
    handleClick($event) {
      this.$emit("changeComponent", $event);
    },
  },
  components: {Button, ButtonWhite, PlaceholderTextInput},
};
</script>


<style lang="scss" scoped>
.close-profile {
  .close-profile-container {
    border-right: 10px;
    background-color: #fff;
    padding: 20px;
    max-width: 728px;

    .close-profile-back {
      padding: 10px 10px 15px 0;

      .back-button {
        display: flex;
        align-items: center;
        gap: 10px;
        border: none;
        background: none;
        color: #6F6F6F;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;

        img {
          width: 20px;
          height: 20px;
        }

      }
    }

    .close-profile-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .content {
      .content-text {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 30px;
      }

      form {
        margin-bottom: 10px;

        div {
          margin-bottom: 12px;

          input {
            margin: 0.4rem;
          }

          label {
            color: #6F6F6F;
            font-size: 18px;
          }
        }
      }

      .content-subtext {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }

    .close-profile-buttons {
      display: flex;
      gap: 14px;
    }
  }
}
</style>
